import Link from "next/link";
import styled from "styled-components";

import Box from "@/design-system/Box";

import Typography from "@/design-system-v3/Typography";

import { theme } from "@/theme";

const Resources = () => {
  return (
    <Box p={{ xs: "24px", md: "40px" }}>
      <Box mb={"24px"}>
        <Typography pb={{ xs: "4px", md: "8px" }} varient="bodyl" color={"gray.500"}>
          RESOURCES
        </Typography>
        <Box height={"1px"} backgroundImage={"linear-gradient(to right,#344054, #34405400)"} />
      </Box>
      <Link className="link__" href={"/learning-hub"}>
        <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
          Blog
        </LinkComp>
      </Link>

      <Link className="link__" href={"/webinars"}>
        <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
          Events & Webinars
        </LinkComp>
      </Link>

      <Link className="link__" href={"/impact-on-aec"}>
        <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
          Our Impact
        </LinkComp>
      </Link>

      <Link className="link__" href={{ pathname: "/contact-us" }}>
        <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
          Contact Us
        </LinkComp>
      </Link>
    </Box>
  );
};

export default Resources;

const LinkComp = styled(Typography)`
  &:hover {
    color: ${theme.colors.base.white};
  }
`;
