import Box from "@/design-system/Box";

import Container from "@/components/CoursePage/BIM_ARCHI_V2/Container/Container";

import { theme } from "@/theme";

import Company from "./Company";
import FooterInfoBar from "./FooterBarInfo";
import HeadingBox from "./HeadingBox";
import Resources from "./Resources";
import SubscriptionBox from "./SubscriptionBox";

const FooterNew = () => {
  return (
    <Box bg={"#0C111D"}>
      <Box mx={{ xs: "0px", md: "unset" }}>
        <Container>
          <Box
            display={"grid"}
            borderLeft={{ md: `1px solid ${theme.colors.gray[800]}` }}
            borderRight={{ md: `1px solid ${theme.colors.gray[800]}` }}
            gridTemplateColumns={{ xs: "1fr", md: "repeat(3, 1fr)" }}
          >
            <SubscriptionBox />
            <HeadingBox />
            <Company />
            <Resources />
          </Box>
        </Container>
      </Box>
      <FooterInfoBar />
    </Box>
  );
};

export default FooterNew;
