import Link from "next/link";
import styled from "styled-components";

import Box from "@/design-system/Box";

import Typography from "@/design-system-v3/Typography";

import { useAppSelector } from "@/hooks/hooks";

import { theme } from "@/theme";

const Company = () => {
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const isNotIndia = Boolean(loginDetails?.userLocation !== "INDIA");
  const isRow = Boolean(loginDetails?.userLocation === "ROW");

  return (
    <Box
      borderRight={{ md: `1px solid ${theme.colors.gray[800]}` }}
      borderBottom={{ xs: `1px solid ${theme.colors.gray[800]}`, md: "none" }}
      p={{ xs: "24px", md: "40px" }}
    >
      <Box mb={"24px"}>
        <Typography pb={"8px"} varient="bodyl" color={"gray.500"}>
          COMPANY
        </Typography>
        <Box height={"1px"} backgroundImage={"linear-gradient(to right,#344054, #34405400)"} />
      </Box>
      <Link className="link__" href={"/about"}>
        <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
          About Us
        </LinkComp>
      </Link>
      <Link className="link__" href={"/courses/building-information-modelling"}>
        <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
          {isNotIndia
            ? "PGP in Building Information Modelling (BIM) for Architects"
            : "BIM Professional Course for Architects V2.0"}
        </LinkComp>
      </Link>
      <Link
        className="link__"
        href={`/courses/${isNotIndia ? "parametric" : "computational"}-design`}
      >
        <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
          {isNotIndia
            ? "PGP in Parametric Design for Architects"
            : "Master Computational Design Course"}
        </LinkComp>
      </Link>
      <Link className="link__" href={"/courses/bim-for-civil-engineers"}>
        <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
          {isNotIndia
            ? "PGP in Building Information Modelling (BIM) for Civil Engineers"
            : "BIM Professional Course For Civil Engineers"}
        </LinkComp>
      </Link>
      {!isNotIndia && (
        <Link className="link__" href={"/courses/interior-design-and-future-tech"}>
          <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
            Post Graduate Program in Interior Design & Future Tech
          </LinkComp>
        </Link>
      )}
      <Link className="link__" href={"/apply-as-mentor"}>
        <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
          Become a Mentor
        </LinkComp>
      </Link>
      {!isRow && (
        <Link className="link__" href={"/hire-from-us"}>
          <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
            Hiring Partners
          </LinkComp>
        </Link>
      )}
      <Link className="link__" href={"/career"}>
        <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
          Careers at Novatr
        </LinkComp>
      </Link>
      <Link className="link__" target="_blank" href={"https://www.novatr.com/media-room-home-page"}>
        <LinkComp mb={"8px"} varient="bodym" weightVarient="semibold" color={"base.white"}>
          Media Room
        </LinkComp>
      </Link>
    </Box>
  );
};

export default Company;

const LinkComp = styled(Typography)`
  &:hover {
    color: ${theme.colors.base.white};
  }
`;
